import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';


import ModalError from '../../components/modal/ModalError';
import { assignTags, createLinkedItem, updateLinkedItem } from '../../services/api/mutationService';
import { getPortfolios } from '../../services/api/queryService';

const PortfolioModal = ({setModalOpen, products, product_parent_id, selectedItems}) => {
  const [formData, setFormData] = useState({
    product_parent_id: ''
  });
  const [error, setError] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([])
  const [createdTags, setCreatedTags] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [getPorfolioTags, { loading, data, refetch }] = useLazyQuery(getPortfolios, {});

  const [assignTagsMutation, {loading: tagLoading}] = useMutation(
      assignTags
  );

  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  console.log(products)

  useEffect(() => {

    const d = products.filter(x => selectedItems.some(w => +x.product_parent_id == w))

    if(d.length === 0){
        setError('Please select products')
    }else{
        const m = d[0].manufacturer.name
        const f = d.filter(x => x.manufacturer.name == m)
        
        if(f.length !== d.length){
            const e = []
            e.push('Selected products belong to different manufacturers and cannot be linked to portfolios that belong to different manufacturers') 
            e.push('* Please select products that are related to only one manufacturer.') 
            setError([...e])
        }else{
            getPorfolioTags({
                variables: {
                  manufacturer: m,
                }
            })
        }
    }
  }, [selectedItems])

  useEffect(() => {
    if(data){
        setTags([...data.getPortfolios])
    }
  }, [data, selectedItems])


  const dispatch = useDispatch();
  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };


  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();

    const tagsInput = tags.filter(w => selectedTags.some(x => x == w.id)).map(x => x.name)

    try {
      const { data } = await assignTagsMutation({
        variables: {
          tags: tagsInput,
          pid: selectedItems,
        }
      });

      if (data.assignTags) {
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully added'
          }
        });
        setModalOpen(false);
      }
    } catch (err) {
      setError(err);
    }
  };

  
  const checkboxChangeHandler = k => {
    const d = [...selectedTags]
    if(k == 'all'){
      if(d.length === tags.length){
        d.splice(0, tags.length)
      }else{
        tags.map(x => {
          if(d.indexOf(x.id) == -1){
            d.push(x.id)
          }
        })
      }
    }else{
      if(d.indexOf(k) > -1){
        d.splice(d.indexOf(k), 1)
      }else{
        d.push(k)
      }
    }

    setSelectedTags([...d])
  }

  const removeTag = k => {
    const d = [...selectedTags]
    if(d.indexOf(k) > -1){
        d.splice(d.indexOf(k), 1)
    }

    setSelectedTags([...d])
  }
  
  const createTag = async tag => {
    const createdTgs = [...createdTags]
    const tgs = [...tags]

    if(tgs.find(x => x.name?.toLowerCase()?.trim() === tag.toLowerCase())){
        const e = []
        e.push('Tag already exists') 
        setError([...e])
    }else{
        tgs.push({
            id: tag,
            name: tag,
            status: 1
        })

        createdTgs.push(tag)

        setCreatedTags([...createdTgs])
        setTags([...tgs])

        setKeyword('')
    }


  }



  return (
    <div >
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {error ? (
            <>
              Selection error
            </>
          ) : (
            <>
              Link items by portfolio tags
            </>
          )}
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <div className="form-group">
            { error && error.map(x => (
              <p>{x}</p>
            )) }
          {/* <ModalError error={error} setError={setError} /> */}
            { !error && (
                <>
                
                    <label htmlFor="name">
                        Select tag
                    </label>
                    <div className="custom__select">
                        <div className="custom__select_inner" onClick={() => setDropdownOpen(true)}>
                            
                            { selectedTags.length === 0 && (
                                <p className="custom__select__placeholder">Select/Input ...</p>
                            ) }

                            { selectedTags.length > 0 && (
                                <div className="custom__select__tags">
                                    <>
                                        { selectedTags.map(x => (
                                            <div className="custom__select__tag" onClick={() => removeTag(tags.find(w => w.id == x).id)}>
                                                <span className='custom__select__title'>{ tags.find(w => w.id == x).name }</span>
                                                <span className='custom__select__tag_close'>x</span>
                                            </div>
                                        )) }
                                    </>
                                </div>
                            ) }

                        </div>
                        <div className="custom__select_icon" onClick={() => setDropdownOpen(true)}>
                            <i class="mdi mdi-chevron-down"></i>
                        </div>

                        { dropdownOpen && (
                            <div className="custom__select__dropdown">
                                <div className="custom__select__dropdown__header">
                                    <div className="custom__select__dropdown__header__tr__checkbox">
                                        <input type="checkbox" checked={ tags.length === selectedTags.length && selectedTags.length > 0 } onChange={() => checkboxChangeHandler('all')} />
                                    </div>
                                    <div className="custom__select__dropdown__header__tr__search">
                                        <input type="text" style={{width: '100%'}} onChange={(e) => setKeyword(e.target.value)} />
                                        
                                        <i class="mdi mdi-magnify"></i>
                                    </div>
                                    <div className="custom__select__create__button">
                                      <button style={{ padding: '5px 14px'}} onClick={() => createTag(keyword)} className="btn btn-primary">Add new</button>
                                    </div>
                                    <div className="custom__select__dropdown__header__tr__close" onClick={() => setDropdownOpen(false)}>
                                        <i class="mdi mdi-close"></i>
                                    </div>
                                </div>
                                <div className="custom__select__dropdown__body">
                                    { !loading && tags && tags.map(x => (
                                        <div onClick={() => checkboxChangeHandler(x.id)} className={`custom__select__dropdown__body__item ${ selectedTags.includes(x.id) ? 'checked' : '' }`} style={{ display: (keyword && x.name.toLowerCase().includes(keyword.toLowerCase())) || (!keyword || keyword?.length == 0)  ? 'flex' : 'none' }}>
                                            <div className={`custom__select__dropdown__body__item__checkbox`}>
                                                <input type="checkbox" checked={ selectedTags.includes(x.id) }  />
                                            </div>
                                            <div className="custom__select__dropdown__body__item__tag">
                                                <span>{x.name}</span>
                                            </div>
                                        </div>
                                    )) }

                                    { loading && (
                                        <div className="spinner">Spinner</div>
                                    ) }
                                </div>
                                <div className="custom__select__dropdown__footer">
                                    <div className="custom__select__dropdown__footer__divider"></div>
                                </div>
                            </div>
                        ) }

                    </div>
                    <label htmlFor="name" style={{ marginTop: '40px' }}>
                        Added tags
                    </label>
                    <div className="custom__tags__wrapper">
                        { createdTags && createdTags.map(x => (
                            <div className="custom__select__tag">
                                <span className='custom__select__title'>{x}</span>
                            </div>
                        )) }
                    
                    </div>
                </>
            ) }
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        { tagLoading && (
          <div className="spinner">Spinner</div>
        ) } 
        { !tagLoading && !error && (
          <button type="submit" className="btn btn-primary" onClick={submitHandler}>
              Save
          </button>
        )}
      </div>
    </div>
  );
};

export default PortfolioModal;
